import React from 'react';
import './ConfirmationModal.css'; // Create CSS for this modal

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Bevestig actie</h2>
        <p>{message}</p>
        <div className="confirmation-buttons">
          <button onClick={onConfirm} className="confirm-button">Ja</button>
          <button onClick={onClose} className="cancel-button">Nee</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
